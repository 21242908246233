import { AdaptiveBranchType } from '@/types/common';

export const adaptiveExams = {
  ISEB: true,
  LON11PLUS: true,
  '11PLUS': false,
  CAT4: false,
  CEMSEL: false,
  GL11PLUS: false,
  SAT: false,
  SHRTY2: true,
  SHRTY3: true,
  SHRTY4: true,
  SHRTY5: true,
  SHRTY6: true,
  SHRTY7: true,
  SHRTY8: true,
  CSSE: false,
  SET: false,
  FSCE11PLUS: false,
  ATOMTEST: true,
};

export const notAdaptiveExamsBySubject = {
  LON11PLUS: {
    CT: true,
  },
  ATOMTEST: {
    CT: true,
  },
};

export const adaptiveExamModes = {
  0: 'EASY',
  1: 'HARD',
};

export const adaptiveExamIndexes = {
  EASY: 0,
  HARD: 1,
};

export const adaptedModes = {
  EASY: {
    content: 'Baseline',
    bg: 'primary500',
    bgHover: 'primary700',
    colorScheme: 'orange',
  },
  HARD: {
    content: 'Harder',
    bg: 'blue600',
    bgHover: 'blue800',
    colorScheme: 'blue',
  },
};

export type AdaptiveExamModesKeys = keyof typeof adaptiveExamModes;

export const getAdaptiveStructure = (
  branches: Array<AdaptiveBranchType> | undefined
): any => {
  return (
    branches?.reduce(
      (acc, branch, index) => ({
        ...acc,
        [adaptiveExamModes[index as AdaptiveExamModesKeys]]: {
          ...branch,
        },
      }),
      {}
    ) || null
  );
};
export type AdaptiveExamModes = keyof typeof adaptedModes;
