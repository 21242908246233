import { z } from 'zod';
import {
  BackEndExamType,
  CustomerProfileDtoSchema,
  FormattedLearnerDtoSchema,
  LearnerProfileDtoSchema,
  PlanDetailsType,
  TargetSchoolType,
} from '@/store/user-profile/schemas';
import { LearnerProfileDto } from '@/store/user-profile/types';

export const UserAuthResponseSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
});

export const UserNotificationSchema = z
  .array(
    z.union([
      z.literal('canReceiveNewAudio'),
      z.literal('canReceiveNewInvites'),
    ])
  )
  .optional();

export type State = z.infer<typeof FormattedLearnerDtoSchema>;
export type StateBE = z.infer<typeof LearnerProfileDtoSchema>;
export type CustomerPayload = z.infer<typeof CustomerProfileDtoSchema>;
export type TargetSchoolType = z.infer<typeof TargetSchoolType>;
export type ExamType = z.infer<typeof BackEndExamType>;
export type PlanDetailsType = z.infer<typeof PlanDetailsType>;
export type UserNotificationType = z.infer<typeof UserNotificationSchema>;

export type Action =
  | { type: 'INIT'; payload: any /* StateBE | CustomerPayload */ }
  | { type: 'SET_GIVEN_NAME'; payload: string }
  | { type: 'SET_SURNAME'; payload: string }
  | { type: 'SET_USERNAME'; payload: string }
  | { type: 'SET_DOB'; payload: string }
  | { type: 'SET_REFERRAL_CODE'; payload: string }
  | { type: 'SET_TARGET_SCHOOLS'; payload: TargetSchoolType[] }
  | { type: 'SET_EXAMS'; payload: ExamType[] }
  | { type: 'SET_IS_ENGLISH_SECOND_LANGUAGE'; payload: boolean }
  | { type: 'SET_HAS_SPECIAL_EDUCATION_NEEDS'; payload: boolean }
  | { type: 'SET_DYSLEXIA'; payload: boolean }
  | { type: 'SET_DYSPRAXIA'; payload: boolean }
  | { type: 'SET_OTHER_SPECIAL_NEEDS'; payload: boolean }
  | { type: 'SET_OTHER_SPECIAL_NEEDS_INPUT'; payload: string }
  | { type: 'SET_IS_HOMESCHOOLED'; payload: boolean }
  | { type: 'SET_PLAN_DETAILS'; payload: PlanDetailsType }
  | { type: 'SET_GUARDIAN_LEARNERS'; payload: LearnerProfileDto[] }
  | {
      type: 'SET_INTERESTS';
      payload: {
        tag: string;
        id: number;
      }[];
    }
  | { type: 'SET_NOTIFICATIONS'; payload: UserNotificationType }
  | { type: 'UPDATE_BEST_STREAK'; payload: number }
  | {
      type: 'UPDATE_WEEKLY_STREAKS';
      payload: {
        daily: number;
        total: number;
      };
    }
  | { type: 'UPDATE_PROFILE_PICTURE'; payload: string }
  | { type: 'UPDATE_STUDENT_ZPD'; payload: string };

export const initialState: State = {
  type: 'learner',
  userId: '',
  givenName: '',
  surname: '',
  isOnboarding: false,
  loginOrigin: 'learner',
  username: '',
  dob: '',
  currentSchool: null,
  grade: null,
  gender: null,
  email: '',
  referralCode: '',
  mode: 'RELAXING',
  targetSchools: [],
  exams: [],
  interests: [],
  notifications: [],
  isEnglishSecondLanguage: false,
  hasSpecialEducationNeeds: false,
  dyslexia: false,
  dyspraxia: false,
  otherSpecialNeeds: false,
  otherSpecialNeedsInput: '',
  isHomeschooled: false,
  planDetails: null,
  invitationStatus: undefined,
  specialEducationNeeds: [],
  isNotificationsEnable: false,
  profilePicture: '',
  plan: 'core',
};
